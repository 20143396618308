'use strict';

const _win = $(window);
let $simpleSlider;
let $visualBoxSlider;

const simpleSlider = function () {
    let isRandomStartSlide = $("#randomic-slide-number").val();
    $simpleSlider = $('.js-herobanner-slider .slider');
    if ($simpleSlider.length) {
        $simpleSlider.each(function () {
            let $slider = $(this);
            let slidesLength = $slider.find('.gallery').length
            if ($slider.hasClass("initialized")) {
                return;
            }
            if (slidesLength > 1) {
                let slickParams = {
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    lazyLoad: 'progressive',
                    dots: false,
                    centerMode: false,
                    swipe: true,
                    arrows: true,
                    prevArrow: '<button type="button" data-role="none" class="slick-prev slick-arrow slider-arrow" aria-label="Prev" role="button" style="display: block;" aria-disabled="false">  </button>',
                    nextArrow: '<button type="button" data-role="none" class="slick-next slick-arrow slider-arrow" aria-label="Next" role="button" style="display: block;" aria-disabled="false">  </button>',
                    responsive: [{
                        breakpoint: 960,
                        settings: {
                            infinite: true,
                            swipe: true,
                            arrows: false,

                        }
                    }
                    ],
                }
                if (isRandomStartSlide) {
                    slickParams.initialSlide = Math.floor(Math.random() * slidesLength);
                }
                $(this).css("visibility", "visible");

                //filtro lo slider sulla base del contenuto della variabile window.userVisibility (impostata da React)
                $slider.on('init', function (event, slick) {
                    setTimeout(() => {
                        filterSliderBasedOnGlobalVariable();
                    },10)
                });

                $(this).slick(slickParams);
                $slider.addClass("initialized");
            }
        });
    }
    $(window).trigger('slick::initialized');
};

$(_win).on('load',function() {
    simpleSlider();
});

// Funzione per gestire lo slider js-herobanner-sliders
const visualBoxSlider = function () {
    let isRandomStartSlide = $("#randomic-slide-number").val();

    let visualBoxSliderOptions = {
        "default": {
            arrows: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            autoplay: false,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        centerMode: true,
                    }
                }
            ]
        }
    };

    $visualBoxSlider = $(".js-herobanner-sliders .slider");
    if ($visualBoxSlider.length) {
        $visualBoxSlider.each(function () {
            let $slider = $(this);
            if ($slider.hasClass("initialized")) {
                return;
            }

            let counterLi = $slider.find(".slick-dots li").length;
            let widthLi = 100 / counterLi + '%';
            $slider.find(".slick-dots li").css('width', widthLi);

            if ($(this).find('.gallery').length > 1) {

                let sliderType = $(this).attr('data-slider');
                if (!sliderType) {
                    sliderType = "default";
                }

                if (isRandomStartSlide) {
                    visualBoxSliderOptions[sliderType].initialSlide = Math.floor(Math.random() * $(this).find('.js-slider').length);
                }
                $(this).css("visibility", "visible");

                //filtro lo slider sulla base del contenuto della variabile window.userVisibility (impostata da react)
                $slider.on('init', function (event, slick) {
                    setTimeout(() => {
                        filterSliderBasedOnGlobalVariable();
                        //to solve the bug where the texts overlap on mobile due to the absolute position, d-none is set to slides > 0, here it is removed
                        $(".js-herobanner-sliders .slider").find(".textBlock").each(function() {
                            $(this).removeClass("d-none")
                        })
                    },10)
                });

                $(this).slick(visualBoxSliderOptions[sliderType]);
                $slider.addClass("initialized");
                handlePlyrInsideSlider($(this));
            }
        });
    }
};


$(document).ready(function () {
    visualBoxSlider();
    simpleSlider();
});

